import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from "gatsby-image"

function getProducts (allMarkdownRemarkGroup, section) {
    let result = [];
	for (let edges of allMarkdownRemarkGroup) {
        for (let item of edges.edges) {
            if (item.node.frontmatter.section === section) {
                result.push(item.node)
            }
        }
    }
    result.sort(function(a, b) {
        return a.frontmatter.nav_order - b.frontmatter.nav_order;
    });
	return result;
}

const Products = (p) => <div className="products">
    {p.product.map( (p, i) => (
        <div className="product" key={i}>
            <Link to={p.fields.slug} className="product__link">
                <figure>
                    <Img fluid={p.frontmatter.image.childImageSharp.fluid} alt={p.frontmatter.heading} />
                </figure>
                <h2>{p.frontmatter.heading}</h2>
            </Link>
        </div>
    ))}
</div>

export default ({category}) => (
    <StaticQuery
        query={graphql`
        query {
            allMarkdownRemark {
                group(field: frontmatter___section) {
                    edges {
                        node {
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                section
                                nav_order
                                heading
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 800, maxHeight: 450) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `}
    render = { data => (
        <Products product={getProducts(data.allMarkdownRemark.group, category)} />
    )}
    />
)


